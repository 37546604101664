<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="crmContracts"
                          base-table="customer-contracts"
                          columnsPrefix="crm-contracts.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.title.crm-contracts"
                >
                    <template #cell(kunden_id)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.kunden_id.id}, refreshTable)">
                            {{ row.item.kunden_id.name }}
                        </a>
                    </template>
                    <template #cell(file)="row">
                        <a href="#" @click.prevent="customerContractUrl(row.item.file)">{{ row.item.file }}</a>
                    </template>
                    <template v-slot:top-actions>
                        <b-form inline class="mb-2">
                            <font-awesome-icon
                                role="button"
                                icon="chevron-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="chevron-right"
                                @click="nextMonth()"
                            />&nbsp;
                        </b-form>
                    </template>

                    <template #cell(contact_person)="row">
                        <div>{{ row.item.contact_person.vorname }} {{ row.item.contact_person.name }}</div>
                        <div>{{ row.item.contact_person.telefon }}</div>
                        <div>{{ row.item.contact_person.email }}</div>
                    </template>

                    <template #cell(todos)="row">
                        <div style="min-width: 800px">
                            <todos :todos="row.item.todos" :callback="refreshTable"></todos>
                        </div>
                    </template>

                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal', {contract_id: row.item.id, customer_id: row.item.kunden_id.id}, refreshTable)">
                                {{ $t('common.button.add_todo') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'CrmContracts',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            type: null,
            month: null,
            dateStart: null,
            dateEnd: null,
            crmStatuses: {
                crm_crm_statuses: null,
                crm_aftersale_statuses: null,
            },
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Crm', ['getContractsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Crm/fetchContracts', 'getContractsTable');
        },
        ctxBaseParams() {
            return {
                'startDate': this.dateStart,
                'endDate': this.dateEnd,
            }
        },
        customerContractUrl(file) {
            this.$root.openDocument(file, 'customer_contracts')
        },
    },
    mounted() {
        this.type = this.$route.meta.type
    }
}
</script>